$bg-light: #E2E5E8;
$bg-success: #7CB342;
$text-muted: #9BA6B0;
$text-primary-color: #00263A;
$fs-1-8: 1.8rem;

@mixin medium {
  @media (min-width: 700px) {
    @content
  }
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 699px) {
    @content
  }
}

@mixin extra-large-screen {
  @media (max-width: 1470px) {
    @content
  }
}

@mixin large-screen {
  @media (max-width: 1000px) {
    @content
  }
}

@mixin medium-screen {
  @media (max-width: 985px) {
    @content
  }
}

@mixin short-screen {
  @media (max-width: 769px) {
    @content
  }
}

@mixin extra-short-screen {
  @media (max-width: 570px) {
    @content
  }
}

@mixin double-extra-short-screen {
  @media (max-width: 335px) {
    @content
  }
}
