@font-face {
  font-family: Orbitron;
  src: url(../assets/fonts/orbitron/Orbitron-Bold.ttf);
}

body {
  overflow: hidden;
}

#splash-screen-background {
  background: url(../assets/img/warehouse1.jpg) no-repeat center center;
  background-size: cover !important;
  width: 100%;
  height: 100vh;
}

#splash-screen-background.iwh1 {
  background: url(../assets/img/warehouse1.jpg) no-repeat center center;
}

#splash-screen-background.iwh2 {
  background: url(../assets/img/warehouse2.jpg) no-repeat center center;
}

#splash-screen-background.iwh3 {
  background: url(../assets/img/warehouse3.jpg) no-repeat center center;
}

#splash-screen-background::after {
  background: rgba(0, 0, 0, 0.8);
  content: close-quote;
  position: absolute;
  width: 100%;
  height: 100vh;
}

#splash-screen-wrapper {
  width: 100vh;
  height: 50vh;
  display: table;
  vertical-align: middle;
}

#splash-screen-logo {
  display: table-cell;
  height: 50%;
}

#splash-screen-logo .ripple-wrapper {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ripple1, .ripple2, .ripple3 {
  position: absolute;
  left: 50%;
  top: 35%;
  background-image: radial-gradient(circle closest-side at center, #fff 0px, #e32526 50px, transparent 80px, #e32526 150px);
  background-position: center center;
  -webkit-animation: radar 3s ease infinite;
  -moz-animation: radar 3s ease infinite;
  animation: radar 3s ease infinite;
  -webkit-border-radius: 100%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 100%;
  -moz-background-clip: padding;
  border-radius: 100%;
  background-clip: padding-box;
}

.ripple2 {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ripple3 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#splash-screen-wrapper .splash-screen-logo img {
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: -30%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 180px;
}

#splash-screen-wrapper .splash-screen-title {
  color: #fff;
  margin: auto;
  position: absolute;
  top: 55%;
  left: 25%;
  bottom: 10%;
  right: 25%;
}

#splash-screen-wrapper .splash-screen-title h1 {
  font-family: Orbitron, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

#splash-screen-wrapper .splash-screen-title .splash-screen-divisor {
  border-top: 1px solid #fff;
  margin-top: -15px;
  margin-bottom: 20px;
}

#splash-screen-wrapper .splash-screen-title .splash-screen-detail span {
  bottom: 35px;
}

#splash-screen-wrapper .splash-screen-title .splash-screen-detail img {
  width: 110px;
}

@-webkit-keyframes radar {
  25%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
  50%{
    width: 40vh;
    height: 40vh;
    margin-left: -20vh;
    margin-top: -20vh;
  }
  75%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

@-moz-keyframes radar {
  25%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
  50%{
    width: 40vh;
    height: 40vh;
    margin-left: -20vh;
    margin-top: -20vh;
  }
  75%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

@keyframes radar{
  25%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
  50%{
    width: 40vh;
    height: 40vh;
    margin-left: -20vh;
    margin-top: -20vh;
  }
  75%{
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

@media(max-height: 320px) {
  #splash-screen-wrapper .splash-screen-title {
    top: 50%;
    left: 15%;
    right: 15%;
  }
  #splash-screen-wrapper .splash-screen-title .splash-screen-divisor {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  #splash-screen-wrapper .splash-screen-title .splash-screen-detail span {
    bottom: 15px;
  }
  #splash-screen-wrapper .splash-screen-title .splash-screen-detail figure {
    margin-top: 15px;
  }
  #splash-screen-wrapper .splash-screen-logo img {
    top: -50%;
    width: 130px;
  }
  .ripple1, .ripple2, .ripple3 {
    top: 25%;
  }
}
